import type { TLmsJob } from '@tl-lms/types/job'
import type { components } from '@tl-lms/types/napis'
import type { TJob } from '@tl-ui/components/job/JobCard.vue'

export function toJobSalary(job: TLmsJob): string | undefined {
  console.log(job)
  if (job.hide_salary) {
    return undefined
  }
  if (job.salary_currency && job.salary_max && job.salary_min) {
    return `${job.salary_currency} ${job.salary_min} - ${job.salary_max}`
  }
  return undefined
}

export function toJobSalaryNAPI(
  job: components['schemas']['JobResponseSchema'],
  adminView?: boolean
): string | undefined {
  if (adminView) {
    if (job.admin_part?.salary_currency && job.admin_part.salary_max && job.admin_part.salary_min) {
      return `${job.admin_part.salary_currency} ${job.admin_part.salary_min} - ${job.admin_part.salary_max}`
    }
  }
  if (job.salary_currency && job.salary_max && job.salary_min) {
    return `${job.salary_currency} ${job.salary_min} - ${job.salary_max}`
  }
  return undefined
}

export function toJobEmploymentType(t: any, jobMode: string): string | undefined {
  return t(`jobBoard.filter.positionType.option.${jobMode}`)
}

export function toJobWorkMode(t: any, workMode: string): string | undefined {
  return t(`jobBoard.filter.workType.option.${workMode}`)
}

export function toJobLocation(t: any, location: string | undefined): string | undefined {
  if (!location) {
    return undefined
  }
  if (location.includes('|')) {
    const locationParts = location.split('|')
    // The last part is the region
    const region = locationParts.pop()
    // The rest is the area
    const translatedLocationParts = locationParts.map((locationPart: string) =>
      t(`jobBoard.filter.location.area.option.${locationPart}`)
    )
    const areaDisplay = translatedLocationParts.join(', ')
    const regionDisplay = t(`jobBoard.filter.location.region.option.${region}`)
    return `${areaDisplay}, ${regionDisplay}`
  } else {
    return `${t('jobBoard.filter.location.region.option.' + location.split(',')[0])}`
  }
}

export function toJobIndustry(t: any, industry: string): string | undefined {
  return t(`jobBoard.filter.industry.option.${industry}`)
}

export function toJobRole(t: any, role: string): string | undefined {
  return t(`jobBoard.filter.role.option.${role}`)
}

export function toTJobSEOTitle(job: TJob): string {
  return `${job.title} Opening at ${job.companyName} in ${job.location}`
}

export function toTJobSEODescription(job: TJob): string {
  return `Join as ${job.title} at ${job.company?.name} in ${job.location}, Apply now! As the leading job board specializing in the tech industry, we connect talented professionals with top-tier companies offering exceptional working environments, competitive packages, and exciting career prospects.`
}

export function toTJob(t: any, job: TLmsJob, _adminView?: boolean): TJob {
  const tCompany = toTCompany(t, job.company)
  return {
    idSlug: job.id_slug,
    to: `/jobs/${job.id_slug}---${toTitleSlug(job.title)}`,
    companyIdSlug: job.company.id_slug,
    postingDate: job.posting_date || '',
    closingDate: job.closing_date || '',
    title: job.title.replace('&amp;', '&'),
    description: job.description,
    location: toJobLocation(t, job.location),
    industry: tCompany.industry,
    employmentType: toJobEmploymentType(t, job.mode),
    workMode: toJobWorkMode(t, job.work_mode),
    role: toJobRole(t, job.role),
    salary: toJobSalary(job),
    status: job.status,
    companyName: tCompany.name,
    logo: tCompany.logo,
    featured: job.featured,
    boosted: job.boosted,
    saved: Boolean(job.saved_job),
    savedIdSlug: job.saved_job?.id_slug,
    company: tCompany
  }
}

export function NAPIToTJob(
  t: any,
  job: components['schemas']['JobResponseSchema'],
  adminView?: boolean
): TJob {
  const tCompany = job.company_part ? NAPIToTCompany(t, job.company_part) : undefined
  return {
    idSlug: job.id_slug,
    to: `/jobs/${job.id_slug}---${toTitleSlug(job.title || '')}`,
    companyIdSlug: tCompany?.idSlug,
    postingDate: job.posting_date || '',
    closingDate: job.closing_date || '',
    title: (job.title || '').replace('&amp;', '&'),
    description: job.description,
    location: toJobLocation(t, job.location || ''),
    industry: tCompany?.industry,
    employmentType: toJobEmploymentType(t, job.mode || ''),
    workMode: toJobWorkMode(t, job.work_mode || ''),
    role: toJobRole(t, job.role || ''),
    salary: toJobSalaryNAPI(job, adminView),
    status: job.status,
    companyName: tCompany?.name,
    logo: tCompany?.logo,
    featured: job.featured,
    boosted: job.admin_part?.boosted,
    saved: Boolean(job.job_activity_part?.saved_record_id_slug),
    savedIdSlug: job.job_activity_part?.saved_record_id_slug as string,
    company: tCompany,
    geoCodeWhiteList: job.geo_code_white_list as string[],
    exclusiveToUserTags: job.exclusive_to_user_tags as string[]
  }
}

export function addMonthsToDate(date: Date, months: number | string) {
  if (typeof months === 'string') {
    months = parseInt(months)
  }
  const dateCopy = new Date(date)
  dateCopy.setMonth(dateCopy.getMonth() + months)
  return dateCopy
}

export function getDateDifferenceInMonths(date: string, date2: string) {
  const d1 = new Date(date)
  const d2 = new Date(date2)
  return (d2.getFullYear() - d1.getFullYear()) * 12 + d2.getMonth() - d1.getMonth()
}

export function toApplicationVisaStatus(t: any, visaStatus: string): string {
  return t(`jobBoard.filter.visaStatus.option.${visaStatus}`)
}

export function toApplicationLanguage(t: any, language: string): string {
  return t(`jobBoard.filter.languages.option.${language}`)
}
