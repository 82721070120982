import type {
  TCreateJobRequest,
  TUpdateJobRequest,
  TApplicantListSavedJobQuery,
  TApplicantListSavedJobResponse
} from '@tl-lms/types/job'
import type { TNAPINormalResponse, TNAPIQuery } from '~/utils/napi-helpers'

function createJob(payload: TCreateJobRequest) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<unknown>('/api/jobs/', 'POST', payload)
}

function updateJob(idSlug: string, payload: TUpdateJobRequest) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<unknown>(`/api/jobs/${idSlug}/`, 'PUT', payload)
}

function generateJobKeywords(description: string): Promise<{ keywords: string[] }> {
  const runtimeConfig = useRuntimeConfig()
  return $fetch(`${runtimeConfig.public.aiBaseUrl}/get-keywords-from-jd`, {
    method: 'POST',
    body: {
      description
    }
  })
}

function listJobOnce(query: TNAPIQuery<'/napis/v1/jobs/', 'get'>) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce('/napis/v1/jobs/', 'get', undefined, query)
}

function getJob(jobIdSlug: string) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce(`/napis/v1/jobs/{job_slug}/`, 'get', { job_slug: jobIdSlug })
}

export function sitemapListJobOnce(
  query: TNAPIQuery<'/napis/v1/jobs/', 'get'>
): Promise<TNAPINormalResponse<'/napis/v1/jobs/', 'get'>> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl

  return $fetch('/napis/v1/jobs/', {
    baseURL,
    method: 'GET',
    headers: {
      'X-Line-Of-Business': runtimeConfig.public.lineOfBusiness,
      'X-Region-Of-Business': regionState.value
    },
    query
  })
}

function listJobsReactive(
  query: TNAPIQuery<'/napis/v1/jobs/', 'get'>,
  asyncDataOptions: Record<string, any> = {}
) {
  const { openApiReactiveList } = useAuthFetch()
  return openApiReactiveList('/napis/v1/jobs/', undefined, query, asyncDataOptions)
}

function listSimilarJobsReactive(
  jobIdSlug: string,
  query: TNAPIQuery<'/napis/v1/similar_jobs/{job_slug}/', 'get'>,
  asyncDataOptions: Record<string, any> = {}
) {
  const { openApiReactiveList } = useAuthFetch()
  const pathParams = {
    job_slug: jobIdSlug
  }
  return openApiReactiveList(
    `/napis/v1/similar_jobs/{job_slug}/`,
    pathParams,
    query,
    asyncDataOptions
  )
}

function visitJob(jobIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<unknown>(`api/job-visit-history/`, 'POST', {
    job_id_slug: jobIdSlug
  })
}

function applicantListSavedJobs(
  query: TApplicantListSavedJobQuery | Ref<TApplicantListSavedJobQuery>,
  asyncDataOptions?: Object,
  oFetchOptions?: Object
) {
  const { fetchOnce } = useAuthFetch()

  async function fetchHandler() {
    const _query = removeFalsyValuesFromPayload(query, ['*'])
    return await fetchOnce<TApplicantListSavedJobResponse>(
      `/api/saved-jobs/`,
      'GET',
      undefined,
      _query,
      oFetchOptions
    )
  }

  return useAsyncData<TApplicantListSavedJobResponse>(
    `job-student-saved-job-list-${JSON.stringify(query)}`,
    fetchHandler,
    {
      // only watch if query is a ref
      watch: isRef(query) || isReactive(query) ? [query] : undefined,
      ...asyncDataOptions
    }
  )
}

function saveJob(jobIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<{ id_slug: string }>(`/api/saved-jobs/`, 'POST', {
    job_id_slug: jobIdSlug
  })
}

function unSaveJob(jobIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<unknown>(`/api/saved-jobs/${jobIdSlug}/`, 'DELETE')
}

export async function canApplyJobInThisRegion(jobGeoCodes: string[]){
  const { fetchOnce } = useAuthFetch()
  try {
    const data = await fetchOnce<Promise<{ country_code: string }>>(`/api/geo-info/`, 'GET')
    if (!data) return false
    const allowedCountries = jobGeoCodes
    return allowedCountries.includes(data.country_code)
  } catch (error) {
    console.log(error)
  }
}

type UseJobReturnType = {
  listJobsReactive: typeof listJobsReactive
  listSimilarJobsReactive: typeof listSimilarJobsReactive
  listJobOnce: typeof listJobOnce
  sitemapListJobOnce: typeof sitemapListJobOnce
  createJob: typeof createJob
  updateJob: typeof updateJob
  generateJobKeywords: typeof generateJobKeywords
  getJob: typeof getJob
  saveJob: typeof saveJob
  unSaveJob: typeof unSaveJob
  visitJob: typeof visitJob
  applicantListSavedJobs: typeof applicantListSavedJobs
  canApplyJobInThisRegion: typeof canApplyJobInThisRegion
}

export function useJob(): UseJobReturnType {
  return {
    listJobsReactive,
    listSimilarJobsReactive,
    listJobOnce,
    sitemapListJobOnce,
    saveJob,
    unSaveJob,
    visitJob,
    createJob,
    updateJob,
    generateJobKeywords,
    getJob,
    applicantListSavedJobs,
    canApplyJobInThisRegion
  }
}
